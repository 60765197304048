import { h } from "vue";
import hlpr from "src/h/helpers.js";
import Landing from "../pages/landing/index.vue";
import LandingLayout from "../pages/landing/layout.vue";
import Discovery from "../pages/discovery/index.vue";
import UserClassrooms from "../pages/user-classrooms/index.vue";
import Main from "../pages/main/index.vue";
import UnsubscribeMail from "../pages/unsubscribe-mail/layout.vue";
import StoryCertificate from "../pages/story-certificate/layout.vue";
import StreamotionLink from "../pages/streamotion-link/layout.vue";
import StoryReport from "../pages/story-report/layout.vue";
import FinishSignUp from "../pages/finish-sign-up/layout.vue";
// import Search from '../components/search.vue' // TODO: top & discovery/index.vue
// import api from "src/h/api.js";

var isLoggedIn = [];

export default function ({ store }) {
  const routes = [
    // LOGIN - Empty layout
    {
      name: "login",
      path: "/login",
      component: () => import("../components/login.vue"),
    },
    // SITEMAP, ABOUT, TEAM, BLOG, TERMS & POLICIES - Landing Layout
    {
      name: "-raw-route-terms",
      path: "/terms-of-use",
      component: LandingLayout,
      children: [
        {
          path: "",
          components: {
            default: () => import("../pages/terms.vue"),
            bottomright: () => import("../components/goback.vue"),
          },
        },
      ],
    },
    {
      name: "-raw-route-privacy",
      path: "/privacy-policy",
      component: LandingLayout,
      children: [
        {
          path: "",
          components: {
            default: () => import("../pages/privacy.vue"),
            bottomright: () => import("../components/goback.vue"),
          },
        },
      ],
    },
    {
      path: "/blog/",
      component: LandingLayout,
      children: [
        {
          name: "-raw-route-blog",
          path: "",
          component: () => import("../pages/blog/public-posts.vue"),
          meta: {
            title: "Blog",
          },
        },
        {
          name: "-raw-route-blog-post",
          path: ":locale/:uri",
          component: () => import("../pages/blog/post.vue"),
        },
      ],
    },
    {
      path: "/welcome/",
      component: LandingLayout,
      children: [
        {
          name: "-raw-route-welcome",
          path: "",
          component: () => import("../pages/welcome/welcome.vue"),
          meta: {
            title: "Welcome",
          },
        },
      ],
    },
    {
      path: "/pricing/",
      component: LandingLayout,
      children: [
        {
          path: "",
          component: () => import("../pages/pricing/pricing.vue"),
          meta: {
            title: "Pricing",
          },
        },
      ],
    },
    {
      path: "/schools/",
      component: LandingLayout,
      children: [
        {
          path: "",
          component: () => import("../pages/schools/schools.vue"),
          meta: {
            title: "Schools",
          },
        },
      ],
    },
    // CONTACTUS - Main Layout
    {
      name: "-raw-route-contactus",
      path: "/contactus",
      component: Main,
      children: [
        {
          path: "",
          components: {
            default: () => import("../pages/contactus/index.vue"),
            bottomright: () => import("../pages/contactus/send.vue"),
            right: () => import("../pages/contactus/right.vue"),
          },
        },
      ],
      meta: {
        title: "Contact us",
      },
    },
    // {
    //   name: "-raw-route-join-campaign",
    //   path: "/jc/:code", // maybe need feature rename join-campain -> join classroom (if will have other campaign types)
    //   component: Main,
    //   children: [
    //     {
    //       path: "",
    //       components: {
    //         default: () => import("../pages/join-campaign/index.vue"),
    //       },
    //     },
    //   ],
    //   meta: {
    //     title: "Join campaign",
    //   },
    // },
    {
      name: "-raw-route-join-classroom",
      path: "/classroom/join/:code",
      component: Main,
      children: [
        {
          path: "",
          components: {
            default: () => import("../pages/join-campaign/classroom.vue"),
          },
        },
      ],
      meta: {
        title: "Join classroom",
      },
    },
    // VideoFeedback
    {
      name: "-raw-route-videofeedback",
      path: "/videofeedback",
      component: Main,
      children: [
        {
          path: "",
          components: {
            default: () => import("../pages/videofeedback/index.vue"),
          },
        },
      ],
      meta: {
        title: "We'd like to see what you say too",
      },
    },
    // Landing - Conditional Layout
    // NOTE: requires store.dispatch("getUser") (currently called on mount, can also be called from router/index.js), re-renders if logged in
    // TODO: recreate splashscreen to avoid rendering the landing page on first load when logged in
    {
      path: "/",
      alias: ["/signup", "/landing"], // NOTE: Aliases knows of its bugs to cause duplicated routes too! TODO: Check.
      component: {
        render: () => {
          if (hlpr.getItem("VOC_USER_TOKEN")) return h(Main);
          // console.debug(
          //   "5a5d9e72: isLoggedIn AAAAAAAAAAAAAAAAAAAAAAAAAAAAAA",
          //   isLoggedIn,
          //   // store.getters.user,
          //   hlpr.getItem("VOC_USER_TOKEN")
          // );
          isLoggedIn = store.getters.user;
          return isLoggedIn ? h(Main) : h(LandingLayout);
        },
      },
      children: [
        {
          name: "route-main-page",
          path: "",
          components: {
            default: {
              render: () => {
                let simpleUI = hlpr.getItem("VOC_USER_SIMPLE_UI") === true;
                if (hlpr.getItem("VOC_USER_TOKEN")) {
                  if (simpleUI) {
                    return h(UserClassrooms);
                  }
                  return h(Discovery);
                }
                // TODO: Discovery being created 2 times all the time 6cda6389
                // let isLoggedIn = store.getters.user;
                // NOTE: Update(vb) probably fixed partially duplicated loads. More testing needed.
                // console.debug(
                //   "5a5d9e72: isLoggedIn BBBBBBBBBBBBBBBBBBBBBBBBBBBBBB",
                //   isLoggedIn,
                //   // store.getters.user,
                //   hlpr.getItem("VOC_USER_TOKEN")
                // );

                return isLoggedIn // TODO: Optimize further to avoid concurent load of Boot and other componenets.
                  ? simpleUI
                    ? h(UserClassrooms)
                    : h(Discovery)
                  : h(Landing);
              },
            },
            bottomright: () => import("../pages/classroom/operations.vue"),
          },
          meta: {
            title: "Voccent: Language Learning and Emotions",
            metaTags: [
              {
                name: "twitter:description",
                content: "Voice & Accent: the way you never experienced.",
              },
              {
                name: "twitter:title",
                content: "Voccent: Language Learning and Emotions",
              },
              {
                name: "twitter:image",
                content: "https://voccent.com/Cc.svg",
              },
            ],
          },
        },
      ],
    },
    // EVERYTHING ELSE - Main Layout (logged in)
    {
      path: "/",
      component: Main,
      children:
        hlpr.getItem("VOC_USER_SIMPLE_UI") === true
          ? [
              {
                name: "-raw-setting-settings",
                path: "settings/:subpage?/:newplan?",
                components: {
                  default: () =>
                    import(
                      /* webpackChunkName: "settings" */ "../pages/settings/index.vue"
                    ),
                },
                meta: {
                  title: "Settings",
                  requiresAuth: true,
                },
              },
              {
                name: "route-plan-pass-user",
                path: "plan-pass/:planid/:cplanid/:continue?",
                props: {},
                components: {
                  default: () => import("../pages/plan-pass/index.vue"),
                  bottom: () => import("../pages/plan-pass/operations.vue"),
                },
                meta: {
                  requiresAuth: true,
                  isRef: false,
                  title: "Plan",
                },
              },
              {
                name: "-raw-route-plan-pass-completed",
                path: "plan-completed/:passid",
                props: {},
                components: {
                  default: () =>
                    import(
                      /* webpackChunkName: "plan-pass-completed" */ "../pages/plan-pass/completed.vue"
                    ),
                },
                meta: {
                  requiresAuth: true,
                  isRef: false,
                  title: "Plan complete",
                },
              },
              {
                name: "404",
                path: "/:catchAll(.*)*",
                components: {
                  default: () => import("../pages/404.vue"),
                },
              },
            ]
          : [
              {
                // Note-ref-4990ff9e: don't forget 2 versions
                name: "-raw-route-empty-channel-name",
                path: "c/id/:uid",
                components: {
                  default: () =>
                    import(
                      /* webpackChunkName: "channel" */ "../pages/channel/index.vue"
                    ),
                  top: () =>
                    import(
                      /* webpackChunkName: "channel" */ "../pages/channel/action.vue"
                    ),
                },
                meta: {
                  title: "Channel",
                  requiresAuth: true,
                },
              },
              {
                // Note-ref-4990ff9e: don't forget 2 versions
                name: "-raw-route-channel",
                path: "c/:uid",
                components: {
                  default: () =>
                    import(
                      /* webpackChunkName: "channel" */ "../pages/channel/index.vue"
                    ),
                  top: () =>
                    import(
                      /* webpackChunkName: "channel" */ "../pages/channel/action.vue"
                    ),
                },
                meta: {
                  title: "Channel",
                  requiresAuth: true,
                },
              },
              // v1
              // {
              //   name: "-raw-route-reports",
              //   path: "reports",
              //   components: {
              //     default: () => import("../pages/reports_v1/index.vue"),
              //     bottomright: () => import("../pages/reports_v1/action.vue"),
              //   },
              //   meta: {
              //     title: "Reports",
              //     requiresAuth: true,
              //   },
              // },
              {
                name: "route-reports",
                path: "/reports",
                components: {
                  default: () =>
                    import(
                      /* webpackChunkName: "reports" */ "../pages/reports/index.vue"
                    ),
                },
                meta: {
                  title: "Reports",
                  requiresAuth: true,
                },
              },
              {
                name: "route-reports-object",
                path: "/reports/:objecttype/:objectid",
                components: {
                  default: () =>
                    import(
                      /* webpackChunkName: "reports" */ "../pages/reports/index.vue"
                    ),
                },
                meta: {
                  title: "Reports",
                  requiresAuth: true,
                },
              },
              {
                name: "route-payouts",
                path: "/payouts",
                components: {
                  default: () =>
                    import(
                      /* webpackChunkName: "payouts" */ "../pages/payouts/index.vue"
                    ),
                },
                meta: {
                  title: "Payouts",
                  requiresAuth: true,
                },
              },
              {
                name: "route-payouts-period",
                path: "/payouts/:periodid",
                components: {
                  default: () =>
                    import(
                      /* webpackChunkName: "payouts" */ "../pages/payouts/index.vue"
                    ),
                },
                meta: {
                  title: "Payouts",
                  requiresAuth: true,
                },
              },
              {
                name: "route-analytics",
                path: "/analytics",
                components: {
                  default: () =>
                    import(
                      /* webpackChunkName: "analytics" */ "../pages/analytics/index.vue"
                    ),
                },
                meta: {
                  title: "Analytics",
                  requiresAuth: true,
                },
              },
              {
                name: "route-streamotion-dashboard",
                path: "/streamotion-dashboard",
                components: {
                  default: () =>
                    import(
                      /* webpackChunkName: "streamotion dashboard" */ "../pages/streamotion-dashboard/index.vue"
                    ),
                },
                meta: {
                  title: "Streamotion dashboard",
                  requiresAuth: true,
                },
              },
              {
                name: "Audio converter",
                path: "aco",
                components: {
                  default: () => import("../pages/audio-converter/index.vue"),
                },
                meta: {
                  title: "Audio converter",
                  requiresAuth: false,
                },
              },
              {
                name: "-raw-route-challenge",
                path: "challenge/:challengeid/:attemptid?",
                props: {},
                components: {
                  default: () => import("../pages/challenge/index.vue"),
                  top: () => import("../pages/challenge/top.vue"),
                },
                meta: {
                  requiresAuth: true,
                  isRef: false,
                  title: "Voccent: Language Learning and Emotions",
                },
              },
              {
                name: "route-plan-pass-author",
                path: "plan-pass-author/:planid/:continue?",
                props: {},
                components: {
                  default: () => import("../pages/plan-pass/index.vue"),
                  bottom: () => import("../pages/plan-pass/operations.vue"),
                },
                meta: {
                  requiresAuth: true,
                  isRef: false,
                  title: "Plan",
                },
              },
              {
                name: "route-plan-pass-user",
                path: "plan-pass/:planid/:cplanid/:continue?",
                props: {},
                components: {
                  default: () => import("../pages/plan-pass/index.vue"),
                  bottom: () => import("../pages/plan-pass/operations.vue"),
                },
                meta: {
                  requiresAuth: true,
                  isRef: false,
                  title: "Plan",
                },
              },
              {
                name: "-raw-route-plan-pass-completed",
                path: "plan-completed/:passid",
                props: {},
                components: {
                  default: () =>
                    import(
                      /* webpackChunkName: "plan-pass-completed" */ "../pages/plan-pass/completed.vue"
                    ),
                },
                meta: {
                  requiresAuth: true,
                  isRef: false,
                  title: "Plan complete",
                },
              },
              {
                name: "-raw-route-playlist",
                path: "playlist/:playlistID", // NOTE - c8e5e672: - "/:challengeIndex"
                props: {
                  // top: { time: true, spectro: true, vaweid: 'ref', color: 'v' },
                  // action: { color: 'h' }
                },
                components: {
                  default: () =>
                    import(
                      /* webpackChunkName: "challenge" */ "../pages/playlist/index.vue"
                    ),
                  // action: () =>
                  //   import(
                  //     /* webpackChunkName: "challenge" */ "../pages/challenge/action.vue"
                  //   ),
                  bottomright: () =>
                    import(
                      /* webpackChunkName: "challenge" */ "../pages/playlist/social-share.vue"
                    ),
                  // bottomleft: () =>
                  //   import(
                  //     /* webpackChunkName: "challenge" */ "../pages/challenge/rating.vue"
                  //   ),

                  // bottomright: () => import( /* webpackChunkName: "challenge" */ '../pages/challenge/rating.vue'),
                  // top: () => import( /* webpackChunkName: "challenge" */ '../pages/challenge/top.vue'),
                },
                meta: {
                  requiresAuth: true,
                  isRef: false,
                  title: "Playlist",
                  metaTags: [
                    {
                      name: "twitter:description",
                      content: "Voice & Accent: the way you never experienced.",
                    },
                    {
                      name: "twitter:title",
                      content: "Accept Challenge",
                    },
                    {
                      name: "twitter:image",
                      content: "https://voccent.com/wave.png",
                    },
                  ],
                },
              },
              {
                name: "Lesson Studio",
                path: "lesson-studio/:lessonID/:challengeIndex",
                props: {},
                components: {
                  default: () =>
                    import(
                      /* webpackChunkName: "challenge" */ "../pages/lesson-studio/index.vue"
                    ),
                  action: () =>
                    import(
                      /* webpackChunkName: "challenge" */ "../pages/challenge/action.vue"
                    ),
                  // right: () =>
                  //   import(
                  //     /* webpackChunkName: "challenge" */ "../pages/challenge/right.vue"
                  //   ),
                  bottomright: () =>
                    import(
                      /* webpackChunkName: "challenge" */ "../pages/playlist/social-share.vue"
                    ),
                },
                meta: {
                  requiresAuth: true,
                  isRef: false,
                  title: "Lesson Studio",
                  metaTags: [
                    {
                      name: "twitter:description",
                      content: "Voice & Accent: the way you never experienced.",
                    },
                    {
                      name: "twitter:title",
                      content: "Accept Challenge",
                    },
                    {
                      name: "twitter:image",
                      content: "https://voccent.com/wave.png",
                    },
                  ],
                },
              },
              {
                name: "-raw-route-record",
                path: "record",
                components: {
                  default: () =>
                    import(
                      /* webpackChunkName: "create" */ "../pages/record/index.vue"
                    ),
                },
                meta: {
                  title: "Record Audio",
                  isRef: true,
                },
              },
              {
                name: "-raw-route-create",
                path: "create",
                components: {
                  default: () =>
                    import(
                      /* webpackChunkName: "create" */ "../pages/create/index.vue"
                    ),
                  action: () =>
                    import(
                      /* webpackChunkName: "create" */ "../pages/create/action.vue"
                    ),
                  right: () =>
                    import(
                      /* webpackChunkName: "create" */ "../pages/create/right.vue"
                    ),
                  bottomright: () =>
                    import(
                      /* webpackChunkName: "create" */ "../pages/create/reset.vue"
                    ),
                  // bottomleft: () => import( /* webpackChunkName: "create" */ '../pages/create/reset.vue'),
                },
                // props: { action: { color: 'v' } },
                meta: {
                  title: "Create",
                  isRef: true,
                },
              },
              {
                name: "-raw-route-edit",
                path: "edit/:challengeid",
                components: {
                  default: () =>
                    import(
                      /* webpackChunkName: "settings" */ "../pages/edit/index.vue"
                    ),
                  right: () =>
                    import(
                      /* webpackChunkName: "create" */ "../pages/edit/right.vue"
                    ),
                  action: () =>
                    import(
                      /* webpackChunkName: "create" */ "../pages/edit/action.vue"
                    ),
                  bottomright: () =>
                    import(
                      /* webpackChunkName: "create" */ "../pages/create/reset.vue"
                    ),
                },
                meta: {
                  title: "Edit",
                  isRef: true,
                  requiresAuth: true,
                },
              },
              {
                name: "route-challenge-studio",
                path: "/challenge-studio/:uid",
                components: {
                  default: () =>
                    import(
                      /* webpackChunkName: "challenge-studio" */ "../pages/challenge-studio/index.vue"
                    ),
                  bottomright: () =>
                    import("../pages/challenge-studio/operations.vue"),
                },
                meta: {
                  title: "Challenge Studio",
                  requiresAuth: true,
                },
              },
              {
                name: "route-organization",
                path: "/organization/:uid",
                components: {
                  default: () =>
                    import(
                      /* webpackChunkName: "organization" */ "../pages/organization/index.vue"
                    ),
                  bottomright: () =>
                    import("../pages/organization/operations.vue"),
                },
                meta: {
                  title: "Organization",
                  requiresAuth: true,
                },
              },
              {
                name: "Edit Lesson Item",
                path: "edit/lesson-item/:lessonID/:challengeIndex",
                components: {
                  default: () =>
                    import(
                      /* webpackChunkName: "settings" */ "../pages/edit-lesson-item/index.vue"
                    ),
                  right: () =>
                    import(
                      /* webpackChunkName: "create" */ "../pages/edit-lesson-item/right.vue"
                    ),
                  action: () =>
                    import(
                      /* webpackChunkName: "create" */ "../pages/edit-lesson-item/action.vue"
                    ),
                },
                meta: {
                  title: "Edit",
                  isRef: true,
                  requiresAuth: true,
                },
              },
              {
                name: "Lesson",
                path: "lesson/:lessonID",
                components: {
                  default: () =>
                    import(
                      /* webpackChunkName: "settings" */ "../pages/lesson/lesson.vue"
                    ),
                  right: () =>
                    import(
                      /* webpackChunkName: "settings" */ "../pages/lesson/right.vue"
                    ),
                },
                meta: {
                  title: "Lesson",
                  requiresAuth: true,
                },
              },
              {
                name: "-raw-setting-settings",
                path: "settings/:subpage?/:newplan?",
                components: {
                  default: () =>
                    import(
                      /* webpackChunkName: "settings" */ "../pages/settings/index.vue"
                    ),
                },
                meta: {
                  title: "Settings",
                  requiresAuth: true,
                },
              },
              {
                name: "route-authoring",
                path: "authoring/:subpage",
                components: {
                  default: () =>
                    import(
                      /* webpackChunkName: "authoring" */ "../pages/authoring/index.vue"
                    ),
                },
                meta: {
                  title: "Authoring",
                  requiresAuth: true,
                },
              },
              {
                name: "-raw-route-user-classrooms",
                path: "my-classrooms",
                components: {
                  default: () =>
                    import(
                      /* webpackChunkName: "user-classrooms" */ "../pages/user-classrooms/index.vue"
                    ),
                  bottomright: () =>
                    import("../pages/classroom/operations.vue"),
                },
                meta: {
                  title: "My classrooms",
                  requiresAuth: true,
                },
              },
              {
                name: "-raw-route-upgrade",
                path: "subscription",
                component: () =>
                  import("../pages/subscription/subscription.vue"),
                meta: {
                  title: "Upgrade",
                  requiresAuth: true,
                },
              },
              {
                name: "-raw-route-blog-post-editor",
                path: "our-posts/editor/:locale?/:uri?",
                component: () => import("../pages/blog/editor.vue"),
                meta: {
                  title: "Blog editor",
                  requiresAuth: true,
                },
              },
              {
                name: "-raw-route-our-posts",
                path: "our-posts",
                component: () => import("../pages/blog/our-posts.vue"),
                meta: {
                  title: "Voccent Blog",
                  requiresAuth: false,
                },
              },
              {
                name: "route-story-studio",
                path: "/story-studio/:uid",
                components: {
                  default: () =>
                    import(
                      /* webpackChunkName: "story-studio" */ "../pages/story-studio/index.vue"
                    ),
                  bottomright: () =>
                    import("../pages/story-studio/operations.vue"),
                },
                meta: {
                  title: "Story Studio",
                  requiresAuth: true,
                },
              },
              {
                name: "route-campus",
                path: "/campus/:uid",
                components: {
                  default: () =>
                    import(
                      /* webpackChunkName: "campus" */ "../pages/campus/index.vue"
                    ),
                  bottomright: () => import("../pages/campus/operations.vue"),
                },
                meta: {
                  title: "Campus",
                  requiresAuth: true,
                },
              },
              {
                name: "route-classroom",
                path: "/classroom/:uid",
                components: {
                  default: () =>
                    import(
                      /* webpackChunkName: "classroom" */ "../pages/classroom/index.vue"
                    ),
                  bottomright: () =>
                    import("../pages/classroom/operations.vue"),
                },
                meta: {
                  title: "Classroom",
                  requiresAuth: true,
                },
              },
              {
                name: "-raw-route-organizations",
                path: "/organizations",
                components: {
                  default: () =>
                    import(
                      /* webpackChunkName: "organizations" */ "../pages/organizations/index.vue"
                    ),
                },
                meta: {
                  title: "Organizations",
                  requiresAuth: true,
                },
              },
              {
                name: "-raw-route-users",
                path: "/users",
                components: {
                  default: () =>
                    import(
                      /* webpackChunkName: "users" */ "../pages/users/index.vue"
                    ),
                },
                meta: {
                  title: "Users",
                  requiresAuth: true,
                },
              },
              {
                name: "route-library",
                path: "/library/:type/:objectid",
                components: {
                  default: () =>
                    import(
                      /* webpackChunkName: "library" */ "../pages/library/index.vue"
                    ),
                  bottomright: () => import("../pages/library/operations.vue"),
                },
                meta: {
                  title: "Library",
                  requiresAuth: true,
                },
              },

              {
                name: "route-library-system",
                path: "/library",
                components: {
                  default: () =>
                    import(
                      /* webpackChunkName: "library" */ "../pages/library/index.vue"
                    ),
                  bottomright: () => import("../pages/library/operations.vue"),
                },
                meta: {
                  title: "Library",
                  requiresAuth: true,
                },
              },
              {
                name: "-raw-route-campuses",
                path: "/campuses",
                components: {
                  default: () =>
                    import(
                      /* webpackChunkName: "campuses" */ "../pages/campuses/index.vue"
                    ),
                },
                meta: {
                  title: "Campuses",
                  requiresAuth: true,
                },
              },
              {
                name: "-raw-route-stories",
                path: "/stories",
                components: {
                  default: () =>
                    import(
                      /* webpackChunkName: "stories" */ "../pages/stories/index.vue"
                    ),
                },
                meta: {
                  title: "Stories",
                  requiresAuth: true,
                },
              },
              {
                name: "-raw-route-story-pass-user",
                path: "/story-pass-user/:storyid",
                components: {
                  default: () =>
                    import(
                      /* webpackChunkName: "story-pass-user" */ "../pages/story-pass-user/index.vue"
                    ),
                },
                meta: {
                  title: "Current stories",
                  requiresAuth: true,
                },
              },
              {
                name: "-raw-route-classrooms",
                path: "/classrooms",
                components: {
                  default: () =>
                    import(
                      /* webpackChunkName: "classrooms" */ "../pages/classrooms/index.vue"
                    ),
                },
                meta: {
                  title: "Classrooms",
                  requiresAuth: true,
                },
              },
              {
                name: "route-story-pass",
                path: "/story-pass/:uid",
                components: {
                  default: () =>
                    import(
                      /* webpackChunkName: "story-pass" */ "../pages/story-pass/index.vue"
                    ),
                  bottomright: () =>
                    import("../pages/story-pass/operations.vue"),
                },
                meta: {
                  title: "Story Pass",
                  requiresAuth: true,
                },
              },
              {
                name: "route-story-pass-history",
                path: "/story-pass-history/:link",
                components: {
                  default: () =>
                    import(
                      /* webpackChunkName: "story-pass-history" */ "../pages/story-pass-history/index.vue"
                    ),
                  bottomright: () =>
                    import("../pages/story-pass-history/operations.vue"),
                },
                meta: {
                  title: "Story Pass History",
                  requiresAuth: true,
                },
              },
              {
                name: "route-mixer",
                path: "/mixer",
                components: {
                  default: () =>
                    import(
                      /* webpackChunkName: "mixer" */ "../pages/mixer/index.vue"
                    ),
                },
                meta: {
                  title: "Mixer",
                  requiresAuth: true,
                },
              },
              {
                name: "route-anomalies",
                path: "/anomalies",
                components: {
                  default: () =>
                    import(
                      /* webpackChunkName: "anomalies" */ "../pages/anomalies/index.vue"
                    ),
                },
                meta: {
                  title: "Anomalies",
                  requiresAuth: true,
                },
              },
              {
                name: "route-genai",
                path: "/genai",
                components: {
                  default: () =>
                    import(
                      /* webpackChunkName: "GenAI Story" */ "../pages/genai/index.vue"
                    ),
                },
                meta: {
                  title: "GenAI Story",
                  requiresAuth: true,
                },
              },
              {
                name: "-raw-route-access-denied",
                path: "403",
                component: () => import("../pages/403.vue"),
                meta: {
                  title: "Access denied",
                },
              },
              {
                name: "404",
                path: "/:catchAll(.*)*",
                components: {
                  default: () => import("../pages/404.vue"),
                },
              },
            ],
    },
    {
      path: "/story-certificate",
      component: StoryCertificate,
      children: [
        {
          name: "-raw-story-certificate",
          path: ":link",
          component: () =>
            import("../pages/story-certificate/story-certificate.vue"),
          meta: {
            title: "Certificate",
          },
        },
      ],
    },
    {
      path: "/story-report",
      component: StoryReport,
      children: [
        {
          name: "-raw-story-report",
          path: ":link",
          component: () => import("../pages/story-report/story-report.vue"),
          meta: {
            title: "Report",
          },
        },
      ],
    },
    {
      path: "/unsubscribe-mail",
      component: UnsubscribeMail,
      children: [
        {
          name: "-raw-route-unsubscribe",
          path: ":socialID/:ID",
          component: () =>
            import("../pages/unsubscribe-mail/unsubscribe-mail.vue"),
          meta: {
            title: "Notifications",
          },
        },
      ],
    },
    {
      path: "/finish-sign-up",
      component: FinishSignUp,
      children: [
        {
          name: "-raw-route-finish-sign-up",
          path: "",
          component: () => import("../pages/finish-sign-up/finish-sign-up.vue"),
          meta: {
            title: "Sign up",
          },
        },
      ],
    },
    {
      path: "/challenge-mini/:attemptID",
      component: () => import("../pages/blog/challenge-mini/index.vue"),
    },
    {
      path: "/streamotion-link",
      component: StreamotionLink,
      children: [
        {
          name: "-raw-streamotion-link",
          path: ":link",
          component: () => import("../pages/streamotion-dashboard/index.vue"),
          meta: {
            title: "Streamotion dashboard",
          },
        },
      ],
    },
  ];

  return routes;
}
