export default {
  state: {
    currentCampus: {
      ID: null,
      Name: null,
    },
    currentCampusPlan: {
      ID: null,
      Name: null,
      Description: null,
      ClassroomID: null,
      CampusID: null,
      PlanPassID: null,
    },
    currentCampusPlanElements: [],
    // split currentCampusPlanElementID and currentCampusPlanElement because maybe user play elementID != null,
    // but this element (story/challenge,...) was deleted, so currentCampusPlanElement will be empty!
    currentCampusPlanElementID: null,
    currentCampusPlanElement: {
      Name: null,
      Description: null,
    },
    currentCampusAccess: {},
    currentCampusTab: null,
    currentCampusOperation: null,
    editingPlanID: "", // it's right, need '' (not null) because of url params
    watchingPlanID: "",
    planSettingsClassroomID: "",
    currentCampusPlanElementState: {},
    currentCampusPlanElementOperation: {},
  },
  getters: {
    currentCampus: (state) => state.currentCampus,
    currentCampusAccess: (state) => state.currentCampusAccess,
    currentCampusTab: (state) => state.currentCampusTab,
    currentCampusOperation: (state) => state.currentCampusOperation,
    editingPlanID: (state) => state.editingPlanID,
    watchingPlanID: (state) => state.watchingPlanID,
    planSettingsClassroomID: (state) => state.planSettingsClassroomID,
    currentCampusPlan: (state) => state.currentCampusPlan,
    currentCampusPlanElements: (state) => state.currentCampusPlanElements,
    currentCampusPlanElementID: (state) => state.currentCampusPlanElementID,
    currentCampusPlanElement: (state) => state.currentCampusPlanElement,
    currentCampusPlanElementState: (state) =>
      state.currentCampusPlanElementState,
    currentCampusPlanElementOperation: (state) =>
      state.currentCampusPlanElementOperation,
  },
  mutations: {
    setCurrentCampus: (state, payload) => (state.currentCampus = payload),
    setCurrentCampusAccess: (state, payload) =>
      (state.currentCampusAccess = payload),
    setCurrentCampusTab: (state, payload) => (state.currentCampusTab = payload),
    setCurrentCampusOperation: (state, payload) =>
      (state.currentCampusOperation = payload),
    setEditingPlanID: (state, payload) => (state.editingPlanID = payload),
    setWatchingPlanID: (state, payload) => (state.watchingPlanID = payload),
    setPlanSettingsClassroomID: (state, payload) =>
      (state.planSettingsClassroomID = payload),
    setCurrentCampusPlan: (state, payload) =>
      (state.currentCampusPlan = payload),
    setCurrentCampusPlanElements: (state, payload) =>
      state.currentCampusPlanElements.splice(
        0,
        state.currentCampusPlanElements.length,
        ...payload,
      ),
    setCurrentCampusPlanElementID: (state, payload) =>
      (state.currentCampusPlanElementID = payload),
    setCurrentCampusPlanElement: (state, payload) =>
      (state.currentCampusPlanElement = payload),
    setCurrentCampusPlanElementState: (
      state,
      payload, // push from components into store
    ) => (state.currentCampusPlanElementState = payload),
    setCurrentCampusPlanElementStateLoading: (state, payload) =>
      (state.currentCampusPlanElementState.loading = payload),
    setCurrentCampusPlanElementOperation: (
      state,
      payload, // read from store into components
    ) => (state.currentCampusPlanElementOperation = payload),
  },
};
